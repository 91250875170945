<template>
  <div class="card p-shadow-6">
    <h1>Acls - Grupos</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="listGroups"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="category.name"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem Grupos de Acls. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('createAclGroups')"
          >
            <Button icon="pi pi-plus" @click="newGroups()" />
          </div>
        </template>
        <Column
          field="name"
          filterField="name"
          header="Nome"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="comment"
          header="Comentário"
          filterField="comment"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            <span class="p-column-title">Activa:</span>
            {{ slotProps.data.active == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('updateAclGroups')"
              icon="pi pi-pencil"
              v-tooltip.left="'Editar'"
              class="p-button-sm p-button-rounded p-button-success"
              @click="editGroup(slotProps.index, slotProps.data)"
            />
            <Button
              v-if="$store.state.auth.user.isEsa || can('manageGroupAcls')"
              icon="pi pi-lock-open"
              v-tooltip.left="'Adicionar ACLs'"
              class="p-button-sm p-button-rounded p-button-info p-ml-1"
              @click="setGroupAcls(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="groupModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (groupModel.id == undefined ? 'Criar' : 'Actualizar') + ' Grupo de ACL'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="aclCtegoryForm" class="p-col-12" @submit.prevent="saveGroup">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="groupModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('groupName') },
                  'form-control',
                ]"
                :name="'groupName'"
              />
              <label for="groupName">Nome do Grupo</label>
            </span>
            <small v-if="errors.has('groupName')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="groupModel.comment"
                v-bind:class="['form-control']"
                :name="'comment'"
              />
              <label for="comment">Comentário</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2" v-if="groupModel.id">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="active">Ativa</label>
            </span>
            <InputSwitch
              v-model="groupModel.active"
              name="active"
              style="'top: 3px;"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveGroup"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
    <Dialog
      :visible.sync="groupAcls.show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        'ACLs do Grupo ' +
        (groupAcls.group == undefined ? '' : groupAcls.group.name)
      "
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
    >
      <GroupAcls
        :group="groupAcls.group"
        v-on:cancel="cancelSetAcls"
        v-on:setGroupAclsOk="cancelSetAcls"
      />
    </Dialog>
  </div>
</template>
<script>
import GroupAcls from "../components/acls/setGroupAcls.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import aclsService from "../services/acls.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "AclsGroups",
  components: {
    GroupAcls,
  },
  data() {
    return {
      loading: true,
      listGroups: [],
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      groupModel: { showForm: false },
      groupAcls: { show: false },
    };
  },
  async created() {
    this.listGroups = await aclsService.getGroups();
    this.loading = false;
  },
  methods: {
    closeForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.groupModel = { showForm: false });
    },
    newGroups() {
      return (this.groupModel.showForm = true);
    },
    editGroup(index, group) {
      return (this.groupModel = {
        index: index,
        showForm: true,
        id: group.id,
        name: group.name,
        comment: group.comment,
        active: group.active == 1 ? true : false,
        group: group,
      });
    },
    saveGroup() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.groupModel.id === undefined) {
          return this.saveNewGroups();
        }

        return this.updateGroup();
      });
    },
    saveNewGroups() {
      let bodyParams = {
        name: this.groupModel.name,
      };

      if (this.groupModel.comment !== undefined) {
        bodyParams.comment = this.groupModel.comment;
      }

      return aclsService.addGroup(bodyParams).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir grupo",
            life: 3000,
          });
        }

        this.listGroups.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Groupo criado",
          detail: "A Groupo foi criada com sucesso",
          life: 3000,
        });
        return this.closeForm();
      });
    },
    updateGroup() {
      let bodyParams = {
        name: this.groupModel.name,
      };

      if (this.groupModel.active !== undefined) {
        bodyParams.active = this.groupModel.active;
      }
      if (this.groupModel.comment !== undefined) {
        if (this.groupModel.comment == null) {
          bodyParams.comment = "";
        } else {
          bodyParams.comment = this.groupModel.comment;
        }
      }
      return aclsService
        .updateGroup(this.groupModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar o grupo",
              life: 3000,
            });
          }
          this.listGroups.splice(this.groupModel.index, 1);
          this.listGroups[this.groupModel.index] = response;
          this.$toast.add({
            severity: "success",
            summary: "Grupo atualizada",
            detail: "A Grupo foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeForm();
        });
    },
    setGroupAcls(group) {
      this.groupAcls.group = group;
      return (this.groupAcls.show = true);
    },
    cancelSetAcls() {
      return (this.groupAcls = { show: false });
    },
  },
};
</script>
