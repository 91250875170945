<template>
  <div :style="{ position: 'relative' }">
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div v-for="category in categoriesAcl" :key="category.id">
      <div
        class="p-d-flex p-jc-row p-ai-center category"
        v-if="category.acls.length > 0"
      >
        <div v-if="category.parent_id" class="p-ml-1">
          {{ category.parent.name }} >
        </div>
        <div class="p-ml-1">
          <b>{{ category.name }}</b>
        </div>
      </div>
      <div
        class="p-d-flex p-jc-row p-ai-center p-ml-3 acls-list"
        v-for="acl in category.acls"
        :key="acl.id"
      >
        <div>
          <InputSwitch
            v-model="groupAcls[acl.id]"
            :id="acl.id"
            style="'top: 3px;"
          />
        </div>
        <div class="p-ml-2">{{ acl.name }} | {{ acl.alias }}</div>
      </div>
    </div>
    <divider />
    <div class="p-d-flex p-jc-end">
      <Button
        :label="'Guardar'"
        icon="pi pi-check"
        class="p-button-success p-mr-2"
        @click="save"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="cancel"
      />
    </div>
  </div>
</template>
<script>
import aclsService from "../../services/acls.service";
import loading from "vue-loading-overlay";
export default {
  name: "setGroupAcls",
  components: {
    Loading: loading,
  },
  props: ["group"],
  watch: {
    group() {
      if (this.group != undefined) {
        this.getInfo();
      }
    },
  },
  data() {
    return {
      loading: false,
      categoriesAcl: [],
      groupAcls: [],
    };
  },
  productService: null,
  async created() {
    if (this.group != undefined) {
      this.getInfo();
    }
  },
  methods: {
    async getInfo() {
      this.loading = true;
      this.groupAcls = await this.getGroupAcls();
      this.categoriesAcl = await aclsService.getCategoriesAcls();
      return (this.loading = false);
    },
    async getGroupAcls() {
      return aclsService.getGroupAcls(this.group.id).then((response) => {
        let groupAcls = {};
        response.map((acl) => {
          groupAcls[acl.id] = true;
        });
        return groupAcls;
      });
    },
    cancel() {
      return this.$emit("cancel");
    },
    save() {
      this.loading = true;
      let aclsChecked = [];
      for (const aclId in this.groupAcls) {
        if (this.groupAcls[aclId] === true) {
          aclsChecked.push(parseInt(aclId));
        }
      }
      return aclsService
        .setGroupAcls(this.group.id, { acls: aclsChecked })
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao alterar as acls ao grupo",
              detail: "",
              life: 3000,
            });
          }

          this.$toast.add({
            severity: "success",
            summary: `Acls do grupo alteradas com sucesso`,
            detail: ``,
            life: 3000,
          });

          return this.$emit("setGroupAclsOk");
        });
    },
  },
};
</script>
<style>
.category {
  background-color: #ff9811;
  height: 30px;
  font-size: 16px;
}
.acls-list {
  height: 30px;
}
</style>
